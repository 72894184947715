<template>
  <div>
    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Event</th>
            <th class="text-left">Staff Name</th>
            <th class="text-left">Center</th>
            <th class="text-left">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in Activities" :key="item._id">
            <td>{{ item.event }}</td>
            <td v-if="item.staffId">
              {{ item.staffId.personalInfo.firstName }}
              {{ item.staffId.personalInfo.lastName }}
            </td>
            <td v-else>undefined</td>
            <td>{{ item.FulfillmentCenter.locationName }}</td>
            <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="totalItems"></v-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      Currpage: 1,
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
    Activities() {
      return this.$store.getters.systemActivities;
    },
    totalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
  },
  methods: {
    GetAllActivities() {
      let payload = {
        page: this.page,
        token: this.getToken,
      };
      this.$store.dispatch("getAllActivities", payload);
    },
  },
  created() {
    this.GetAllActivities();
  },
  watch: {
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        this.$store.dispatch("getAllActivities", payload);
      }
    },
  },
};
</script>